import type { RpcMethodParameters } from '@scayle/storefront-nuxt'
import type { AsyncRpcOptions } from '~/utils/types'

type Options = Partial<{
  params: MaybeRefOrGetter<RpcMethodParameters<'getProductsByReferenceKey'>>
  options?: AsyncRpcOptions<'getProductsByReferenceKey'>
}>

export function useAsyncProductsByReferenceKey({
  params,
  options,
}: Options = {}) {
  return useAsyncRpc('getProductsByReferenceKey', toValue(params), options)
}
